export const getAllTags = async (input, wsid) => {
    input = input || '';
    wsid = wsid || '';
    const result = await logFetchAJAX(getUrl(`/api/tasks/tags?q=${encodeURIComponent(input)}&workspace_id=${encodeURIComponent(wsid)}`), {
        method: 'GET'
    });
    if ((typeof(result) != 'undefined') && (result != null) && (result.hasOwnProperty('tags'))) {
        return result['tags'];
    } else {
        //console.log("getAllTags RESULT:", result);
        return {};
    }
}
